import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Modal,
  Box,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CloseIcon from '@mui/icons-material/Close';
import useSound from 'use-sound';
import thala_song from '../thala_song.mp3'
import moye_moye from '../moye_moye.mp3'

const ThalaReviewSystem = () => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false)

  const [isThala, setIsThala] = useState(false)
  const [reason, setReason] = useState('')

  const isMobile = useMediaQuery('(max-width:600px)');
  const [playThalaSound, {stop: stopThala}] = useSound(thala_song, {volume: isMuted ? 0.0 : 0.1, loop: true})
  const [playSadSound, {stop: stopSad}] = useSound(moye_moye, {volume: isMuted ? 0.0 : 0.1, loop: true})


  const handleOpen = () => {
    checkIfThala()
    setOpen(true);
  };

  const handleClose = () => {
    stopThala()
    stopSad()
    setOpen(false);
    setIsMuted(false)
    setInputValue('')
    setIsThala(false)
  };

  const handleMute = () => {
    setIsMuted(!isMuted);
  };

  const checkIfThala = () => {
    if (inputValue.trim().replaceAll(' ','').length === 7){
        setIsThala(true)
        setReason(`${inputValue.trim().replaceAll(' ','').split('').join('+')}=7`)
        stopSad()
        playThalaSound()
    }
    else if (!/\D/.test(inputValue)){
        let sum = inputValue.toString().split('').reduce((sum, digit) => sum + parseInt(digit, 10), 0)
        if (sum === 7){
            setIsThala(true)
            setReason(`${inputValue.trim().split('').join('+')}=7`)
            stopSad()
            playThalaSound()
        } else {
            setIsThala(false)
            stopThala()
            playSadSound()
        }
    }
    else if (inputValue.includes('7') || inputValue.includes('seven')){
        setIsThala(true)
        setReason(`${inputValue} contains 7!`)
        stopSad()
        playThalaSound()
    }
    else {
        setIsThala(false)
        stopThala()
        playSadSound()
    }
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom>
        Thala Review System
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid black',
          borderRadius: '5px',
          padding: '10px',
          width: isMobile ? '100%' : '50%',
          maxWidth: '400px',
        }}
      >
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          placeholder="Enter something, like 2023 or MS Dhoni"
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ marginLeft: '10px' }}
          onClick={handleOpen}
        >
          Review
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isMobile ? '100%' : '50%',
            maxWidth: '400px',
            bgcolor: 'background.paper',
            border: '5px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleMute}>
              {!isMuted ? <VolumeUpIcon /> : <VolumeOffIcon />}
            </IconButton>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              margin: "1%",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
              width: '100%',
              backgroundImage: isThala ? `url(${process.env.PUBLIC_URL}/thala.gif)` : `url(${process.env.PUBLIC_URL}/sad.gif)`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <Typography variant="h6" component="h2" gutterBottom>
            {isThala ? 'Thala For A Reason!' : 'No Thala For You!'}
          </Typography>
          { isThala ?
            <Typography variant="h6" component="h5" gutterBottom>
                {reason}
            </Typography>
            : <></>
          }
          
        </Box>
      </Modal>
    </Container>
  );
};

export default ThalaReviewSystem;